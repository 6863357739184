function requestAPI(data) {
  return fetch(`${process.env.REACT_APP_BASE_URL}transaction-sgh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((res) => res.json())
}

export default function actionSaveTransaction(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'SAVE_TRANSACTION_BEGIN'
      })

      const result = await requestAPI(data)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'SAVE_TRANSACTION_SUCCESS',
        payload: result.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'SAVE_TRANSACTION_FAILURE',        
    error: error    
  })
}