import React from 'react'

const Policy = () => (
  <div className="policy">
    <h1>KEBIJAKAN PRIVASI SUPER GROSIR HERBAL</h1>

    <p>Super Grosir Herbal adalah aplikasi pembelian Grosir Herbal, dengan diskon harga yang akan tampil di aplikasi dengan minimal belanja tertentu.</p>
    <p>Pemesanan melalui aplikasi Super Grosir Herbal, akan diantarkan baik secara langsung maupun via jasa pengiriman ke alamat agen tersebut.</p>
    <p>Dengan menggunakan aplikasi Super Grosir Herbal, anda telah mempercayakan informasi Anda kepada kami terkait data pribadi (Nama, Alamat, Email, nomer Hp) serta pemesanan herbal yang anda lakukan.</p>
    <p>Kebijakan Privasi ini bertujuan untuk membantu Anda memahami informasi yang kami kumpulkan, alasan kami mengumpulkannya, dan cara untuk memperbarui serta mengelola informasi Anda.</p>

    <ol>
      <li>
        <span>Informasi apa yang kami kumpulkan?</span>Data diri pribadi pelanggan, terkait nama, alamat pengiriman, nomor telepon, dan alamat email.
      </li>
      <li>
        <span>Dari mana kami mengumpulkan informasi tersebut?</span> Data kami kumpulkan melalui proses registrasi di aplikasi android Super Grosir Herbal dan proses pendaftaran oleh Agen Super Grosir Herbal.
      </li>
      <li>
        <span>Untuk apa informasi tersebut kami gunakan?</span> Kami menggunakan informasi tersebut untuk proses pemesanan dan pengiriman ke alamat anda.
      </li>
      <li>
        <span>Bagaimana pelanggan dapat mengontrol informasi pribadinya?</span> Pelanggan dapat mengubah data pribadinya melalui halaman pengaturan bagian edit profil pada aplikasi android Super Grosir Herbal.
      </li>
      <li>
        Menjaga keamanan informasi Anda Kami tidak akan menggunakan data pribadi anda untuk kepentingan selain transaksi pemesanan Super Grosir Herbal.
      </li>
    </ol>
  </div>
)

export default Policy

