import React, { useState } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

import { createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'

import ReactGA from 'react-ga'

import Routes from './Routes'

import { actionAlert } from './store/actions/util'
import { Splash as SplashView } from './views'

import bannerReducer from './store/reducers/banner'
import authReducer from './store/reducers/auth'
import categoryReducer from './store/reducers/category'
import utilReducer from './store/reducers/util'
import producerReducer from './store/reducers/producer'
import productReducer from './store/reducers/product'
import cartReducer from './store/reducers/cart'
import transactionReducer from './store/reducers/transaction'

ReactGA.initialize('UA-48999648-3', { debug: false })

const rootReducer = combineReducers({
  banner: bannerReducer,
  auth: authReducer,
  category: categoryReducer,
  util: utilReducer,
  producer: producerReducer,
  product: productReducer,
  cart: cartReducer,
  transaction: transactionReducer
})

const Logger = ({ dispatch }) => {
  return next => action => {
    if(action.error) {
      dispatch(actionAlert(true, 'error', action.error.message))
    }

    if(action.success) {
      dispatch(actionAlert(true, 'success', action.success.message))
    }

    return next(action)
  }
}

const store = createStore(rootReducer, applyMiddleware(ReduxThunk, Logger))
const browserHistory = createBrowserHistory()

browserHistory.listen((location, _) => {
  ReactGA.pageview(location.pathname)
})

const App = () => {
  const [loading, setLoading] = useState(true)

  return (
    <Provider store={store}>
      {
        (loading)
        ? <SplashView handleEndedSplash={() => setLoading(false)} />
        : <Router history={browserHistory}>
            <Routes />
          </Router>
      }
    </Provider>
  )
}

export default App
