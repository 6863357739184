import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'

import { connect } from 'react-redux'
import { actionAlert } from '../../../../store/actions/util'

const schema = {
  name: {
    presence: { 
      allowEmpty: false, 
      message: 'Nama harus diisi' 
    }
  },
  email: {
    presence: false,
    email: {
      message: 'Alamat email tidak valid'
    }
  },
  phone: {
    presence: { 
      allowEmpty: false, 
      message: 'Nomor telepon harus diisi' 
    },
    numericality: { 
      onlyInteger: true, 
      notValid: 'Nomor telepon harus berupa angka' 
    }
  },
  password: {
    presence: { 
      allowEmpty: false, 
      message: 'Kata sandi harus diisi' 
    }
  },
  address: {
    presence: { 
      allowEmpty: false, 
      message: 'Alamat harus diisi' 
    }
  }
}

const Form = ({ 
  loading,
  onRegister, 
  dispatchAlert 
}) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      type:'sgh'
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const hasError = field =>formState.errors[field] ? true : false

  const handleRegister = (event) => {
    event.preventDefault()

    if(formState.isValid) {
      onRegister(formState.values)
      return
    }

    dispatchAlert(
      true, 
      'error', 
      hasError('name')
      ? formState.errors.name[0]
      : hasError('email')
      ? formState.errors.email[0] 
      : hasError('phone')
      ? formState.errors.phone[0] 
      : hasError('password')
      ? formState.errors.password[0] 
      : formState.errors.address[0]
    )
  }


  return (
    <div className="cover-pagedaftarform">
      <div className="cover-headerdaftaragen">
        <div className="judul">
          <h2>Data diri</h2>
          <p>Isilah data diri anda secara lengkap</p>
        </div>
      </div>
      <div className="formdaftar">
        <div className="card-daftar">
          <div className="close">
            <span></span>
          </div>
          <div className="name">
            <label htmlFor="name">Nama lengkap</label>
            <input 
              type="text" 
              name="name" 
              id="name" 
              onChange={handleChange}
              value={formState.values.name || ''}
            />
          </div>
          <div className="phone">
            <label htmlFor="phone">Nomor whatsapp / User login</label>
            <input 
              type="text" 
              name="phone" 
              id="phone" 
              placeholder="contoh : 081222333444"
              onChange={handleChange}
              value={formState.values.phone || ''}
            />
          </div>
          <div className="password">
            <label htmlFor="password">Password</label>
            <input 
              type="password" 
              name="password" 
              id="password" 
              onChange={handleChange}
              value={formState.values.password || ''}
            />
          </div>
          <div className="email">
            <label htmlFor="email">Email</label>
            <input 
              type="text" 
              name="email" 
              id="email" 
              onChange={handleChange}
              value={formState.values.email || ''}
            />
          </div>
          <div className="address">
            <label htmlFor="address">Alamat</label>
            <textarea 
              name="address" 
              id="address" 
              cols="30" 
              rows="3"
              onChange={handleChange}
              value={formState.values.address || ''}
            ></textarea>
          </div>
          <div className="cover-btndaftar">
            <a onClick={handleRegister} className="btn-daftar">{(loading)? 'Menunggu..' : 'Daftar'}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

Form.propTypes = {
  loading: PropTypes.bool.isRequired,
  onRegister: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)