import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, ModalSuccess } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionRegister } from '../../store/actions/auth'

const Register = ({ 
  history,
  register, 
  dispatchRegister
}) => {
  const [onState, setOnState] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)

  useEffect(() => {
    if(register.success && onState) {
      setOnState(false)
      setModalSuccess(true)
    }
  }, [register.success, onState])

  const handleRegister = (data) => {
    if(!register.loading) {
      dispatchRegister(data)
      setOnState(true)
    }
  }

  const handleHideModal = () => {
    console.log('HIDE')
  }

  const handlePageHome = () => {
    setModalSuccess(false)
    history.push('/')
  }

  return (
    <div className="container">
      <Form 
          loading={register.loading}
          onRegister={handleRegister}
        />

      <ModalSuccess
        modalShow={modalSuccess}
        onModalHide={handleHideModal}
        onModalClick={handlePageHome}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    register: {
      loading: state.auth.register.loading,
      success: state.auth.register.success
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchRegister: (data) => dispatch(actionRegister(data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Register)